import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Bash`}</h1>
    <h2>{`Open `}<inlineCode parentName="h2">{`.bash_profile`}</inlineCode>{` in Visual Studio Code`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`code ./~.bash_profile
`}</code></pre>
    <h2>{`Define a Bash alias`}</h2>
    <p>{`Open bashrc file in editor.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`code ./~.bashrc
`}</code></pre>
    <p>{`And add you custom alias in following format.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bsah"
      }}>{`alias alias_name='command'
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      